 <template>
	<el-dialog title="添加物流公司" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='80px'>
			<el-form-item label="公司名称">
				<el-input v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item label="联系人">
				<el-input v-model="form.link_man" clearable></el-input>
			</el-form-item>
			<el-form-item label="联系电话">
				<el-input v-model="form.link_tel" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					link_man:'',
					link_tel:'',
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){
				this.form.name=''
				this.form.link_man=''
				this.form.link_tel=''
				this.form.mark=''
			},

			//提交
			sub(){

				//物流公司简称
				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少物流公司名称"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'company_add_by_admin',
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			}
		}
	}
</script>